import React from 'react';

import styles from './WebView.module.scss';

const WebView = (props) => {
  const { url, title } = props;

  return (
    <iframe frameBorder="0" className={styles.Frame} title={title} src={url} />
  );
};

export default WebView;