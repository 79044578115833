import React from 'react';
import ReactModal from 'react-modal';

import styles from './Home.module.scss';
import JoonaLogo from './img/Joona_Logo.png';
import BPLogo from './img/bp_logo.png';

ReactModal.setAppElement('#ModalPortal');

const Home = () => {
  const [showModal, setShowModal] = React.useState(false);
  const onSignUp = React.useCallback((e) => {
    e.preventDefault();
    setShowModal(true);
  }, [setShowModal]);

  return (
    <div className={styles.Container}>
      <main className={styles.Main}>
        <header className={styles.Header}>
          <figure>
            <img className="brand" src={JoonaLogo} alt="Joona Logo" />
          </figure>
          <figure className={styles.Tagline}>
            <span>Track burnout. Boost resilience.</span>
            <img className="tagline" src={BPLogo} alt="BrightPath Collective Logo." />
          </figure>
        </header>
        <article className={styles.Article}>
          <h1>Driven to make work work better.</h1>
          <p className="display">A teams-based business intelligence platform for tracking, optimizing, and incentivizing health and&nbsp;resilience.</p>
          <p><b>Stay Tuned...</b></p>
        </article>
        <section className={styles.Newsletter}>
          <button className={styles.Button} onClick={onSignUp}><span className="material-icons">play_arrow</span>Sign up for product updates.</button>
        </section>
      </main>
      <div className={styles.Sails} />
      <ReactModal
        isOpen={showModal}
        contentLabel="Joona News Signup"
        onRequestClose={() => setShowModal(false)}
        className={styles.Modal}
        overlayClassName={styles.Overlay}
      >
        <iframe title="joona-signup" src="https://cdn.forms-content.sg-form.com/3a79a291-0550-11ec-9cd4-16f3bee69925"/>
      </ReactModal>
    </div>
  );
};

export default Home;