import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import WebView from './views/WebView';
import Home from './views/Home';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/privacy">
          <WebView
            title="Privacy Policy"
            url="https://www.termsfeed.com/live/5cdf227b-6449-40e9-8cd1-2ad0353d66c6"
          />
        </Route>
        <Route path="/terms">
          <WebView
            title="Terms and Conditions"
            url="https://www.termsfeed.com/live/f1ff5327-f8cf-45ad-91dc-cec71611755f"
          />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
